body {
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  font-family: Inter, Arial, sans-serif;
  background-color: rgba(18, 20, 30, 0.7);
}

.wrapper {
  position: absolute;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.card-custom {
  background-color: white;
  height: auto;
  min-height: 50%;
  width: 35%;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.card-header-custom {
  height: 50px;
  background: #f6f7ff;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  line-height: 50px;
  color: #142275;
  padding-left: 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem 0.25rem 0 0;
}

.close-btn {
  height: 50px;
  width: 12px;
  position: absolute;
  top: 0;
  right: 1.25rem;
  cursor: pointer;
  background-position: center;
  background-image: url("assets/images/close.svg");
  background-repeat: no-repeat;
}

.card-body-custom {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem 1.75rem 0.25rem 1.75rem;
}

.card-footer-custom {
  background-color: white !important;
  border: none;

  padding: 0.75rem 1.75rem 1.5rem 0.75rem;
  border-radius: 0.25rem;
}

.readonly-group {
  margin-bottom: 10px;
}

.readonly-group h6 {
  font-size: 0.8vw;
  font-weight: bold;
  color: #142275;
  margin: 0;
}

.readonly-group h5 {
  font-size: 0.8vw;
  color: #142275;
  font-weight: normal;
  margin: 0;
}

.section-heading {
  font-size: 0.8vw;
  font-weight: bold;
  color: #142275;
}

.amount {
  font-weight: bold;
}

.currency {
  margin-left: 0.25rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mar-btm {
  margin: 0.7rem 0 0.7rem 0;
}

.mar-rgt {
  margin-right: 0.4rem;
}

.form-coupled,
.form-check-coupled {
  margin-bottom: 1rem;
}

.no-btm-margin {
  margin-bottom: 0 !important;
}

.form-coupled > label {
  font-size: 0.7vw;
  color: #142275;
  margin-bottom: 0.1rem;
  display: block;
}

.pay-textfield {
  width: calc(100% - 1.5rem);
}

.form-coupled > input {
  background: rgba(134, 134, 134, 0.05);
  border: 1px solid rgba(72, 99, 255, 0.1);
  font-size: 12px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  color: #142275;
  padding: 0.375rem 0.75rem;
  height: 1.5rem;
  border-radius: 0.25rem;
}

.pay-form > div {
  background: rgba(134, 134, 134, 0.05);
  border: 1px solid rgba(72, 99, 255, 0.1);
  font-size: 0.8vw;
  color: #142275;
  padding: 0.775rem 0.75rem 0.375rem;
  height: calc(1.75em);
  border-radius: 0.25rem;
}

.form-check-coupled .custom-form-check-label {
  font-size: 0.8vw;
  vertical-align: middle;
  color: #142275;
}

.form-check-coupled > input {
  margin-top: 8px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c1c5da;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c5da;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c1c5da;
}

.width-50 {
  width: calc(50% - 0.2rem);
  display: inline-block;
}

btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.align-rgt {
  float: right;
}

.align-mid {
  text-align: center;
}

/*  fix for yellow input background color */
.StripeElement--webkit-autofill {
  background-color: rgba(134, 134, 134, 0.05) !important;
  border: 1px solid rgba(72, 99, 255, 0.1) !important;
 }   

/* Payment method dropdown starts here */

.select-dropdown__wrapper {
  position: relative;
  padding: 0 !important;
  width: 50%;
}

.dropdown-field {
  width: 100%;
  background: rgba(134, 134, 134, 0.05);
  border: 1px solid rgba(72, 99, 255, 0.1);
  font-size: 0.9vw;
  color: #142275;
  padding: 1rem 0 0.375rem 0.75rem;
  height: calc(2em);
  border-radius: 0.25rem;
  cursor: default;
  display: flex;
  align-items: center;
  gap: 5px;
}

.select-dropdown__wrapper > label {
  position: absolute;
  top: 4px;
  color: rgba(137, 144, 186, 0.7);
  font-size: 0.7vw;
  padding-left: 0.75rem;
  left: 0;
}

.select-dropdown__list-wrapper {
  display: none;
  width: calc(100% + 0.75rem);
  border: 1px solid rgba(72, 99, 255, 0.1);
  position: absolute;
  z-index: 100;
  background: white;
}

.select-dropdown__list-wrapper > ul {
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.select-dropdown__list > li {
  font-size: 0.9vw;
  line-height: 24px;
  line-height: 2rem;
  color: #142275;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(72, 99, 255, 0.1);
  cursor: pointer;
  padding-left: 0.75rem;
}

.select-dropdown__list > li:hover {
  background-color: rgba(72, 99, 255, 0.1);
}

.dropdown-arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background-image: url("assets/images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.is-arrow {
  transform: rotate(180deg);
}

.is-open {
  display: block;
}

.instruction {
  font-size: 0.7vw;
  color: rgba(20, 34, 117, 0.6);
  margin: 0.1rem 0 0.5rem 0;
  display: block;
}

.add-new-btn {
  margin-top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  line-height: 22px;
  color: #4863ff;
  outline: none;
}

.add-icon {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("assets/images/add.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.addnew-txt {
  margin-left: 27px;
}

/* Payment method dropdown ends here */

.button-pri,
.button-sec {
  background-color: #ff5b24;
  color: white;
  border-radius: 5px;
  border: 1px solid #ff5b24;
  font-size: 0.8vw;
  margin-left: 7px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.button-sec {
  background-color: white;
  color: #ff5b24;
}

.button-pri:hover {
  background-color: #ec4d18;
}
.button-pri:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-sec:hover {
  background-color: rgba(255, 91, 36, 0.05);
  outline: none;
}

/*Input error message starts here*/
.input-error-msg {
  font-size: 0.7vw;
  vertical-align: middle;
  color: #f00000;
  height: 20px;
  margin-top: 5px;
}

.error-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  background-image: url("assets/images/error.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.input-error-msg > span {
  margin-left: 17px;
}

/*Input error message ends here*/

/*Error page*/
.err-message {
  text-align: center;
  font-weight: bold;
  font-size: 1.5vw;
  color: #142275;
}
.avatar {
  background-image: url("assets/images/cat.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.error {
  min-height: 40%;
  height: 50%;
}

.error .card-header-custom {
  background: transparent;
  border: none;
}

.error .card-header-custom {
  background: transparent;
  border: none;
}

.error > .card-body-custom {
  padding-top: 0;
  padding-bottom: 50px;
}

.error-content {
  position: relative;
  text-align: center;
}

.loading {
  position: relative;
  top: 50%;
  transform: translateY(112%);
  text-align: center;
}

.loading-content {
  position: relative;
  top: 50%;
  text-align: center;
}

.new-card {
  padding: 0 !important;
}
/*Error page ends here*/

/* Loader screen */
.pay-loader {
  margin-left: auto;
  margin-right: auto;
  border: 6px solid #e7e9f1;
  border-top: 6px solid #7d85b3;
  width: 30px;
  height: 30px;
  border-radius: 55%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
  0% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

.please-wait {
  text-align: center;
  font-weight: bold;
  font-size: 1.2vw;
  color: #142275;
  line-height: 55px;
}

/*Large devices*/
@media (min-width: 992px) {
}

/*Extra large*/
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
  .card-header-custom {
    padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  }

  .form-coupled {
    margin-bottom: 1.2rem;
  }

  .card-body-custom {
    padding: 1.5rem 2.25rem 0.75rem 2.25rem;
  }
}
.no-click {
  opacity: 0.5;
  pointer-events: none;
}

.selection-option {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 13px;
}

.payment-details-dropdown-details {
  display: flex;
  align-items: center;
  gap: 5px
}